import { render, staticRenderFns } from "./self_help_reg.vue?vue&type=template&id=57d4aaef&scoped=true&"
import script from "./self_help_reg.vue?vue&type=script&lang=js&"
export * from "./self_help_reg.vue?vue&type=script&lang=js&"
import style0 from "./self_help_reg.vue?vue&type=style&index=0&id=57d4aaef&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d4aaef",
  null
  
)

export default component.exports